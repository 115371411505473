import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de fins a 5 cm de diàmetre, de color groc brut i amb pèls pel marge disposats d’una forma paral·lela, que es veu quan es posa el capell a contrallum. Davall el capell les làmines són del mateix color que aquest, atapeïdes, adnades i un poc forcades. El peu a vegades presenta una espècie de regruix rodó que envolta la part superior. Les espores són de color crema clar en massa, de subgloboses a el·lipsoidals, de 6,5-8 x 5,5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      